/* @keyframes slidein {
  from {
    translate: 400px -10px;
  }

  to {
    translate: 0 -10px;
  }
} */

.cartSideMenuContainer {
  /* animation-duration: 250ms; */
  /* animation-name: slidein; */
  position: absolute;
  box-sizing: border-box;
  width: 400px;
  height: 70vh;
  padding: 16px 28px;
  background-color: white;
  border: 1px solid rgba(156, 156, 156, 0.7);
  border-radius: 8px;
  z-index: 10;
  translate: 0 -10px;
}

.cartSideMenuContainer::before {
  content: " ";
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: white;
  border-top: 1px solid rgba(156, 156, 156, 0.5);
  border-left: 1px solid rgba(156, 156, 156, 0.5);
  rotate: 45deg;
  translate: 134px -20px;
}

.quantitySelectorRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.itemCount {
  margin-bottom: 2px;
}

.cartSideMenuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;
  border-bottom: 1px solid rgba(156, 156, 156, 0.5);
}

.cartSideMenuItem > section {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.cartSideMenuItemImage {
  width: 65px;
  /* border-radius: 50%;
  border: 1px solid rgba(156, 156, 156, 0.7); */
}

.cartSideMenuItemListContainer {
  display: flex;
  flex-direction: column;
  gap: 22px;
  height: calc(100% - 100px);
  overflow-y: auto;
  padding-inline: 14px;
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(187, 187, 187);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(170, 169, 169);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(158, 158, 158);
}

.cartSideMenuItemName {
  font-weight: 400;
  opacity: 0.6;
}

.thirdSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.cartItemPrice {
  font-weight: 400;
  opacity: 0.6;
}

.cartItemSideMenuPriceNumber {
  margin-top: -3px;
}

.totalAmmount {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  bottom: 20px;
  right: 0;
  padding-inline: 28px;
}

.addRemove {
  font-size: 16px;
  cursor: pointer;
}

.titleRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 8px 0 16px;
}

.depositText {
  color: var(--helperFontColor);
  font-size: 10px;
  text-align: end;
}
