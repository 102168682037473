.container {
  position: fixed;
  top: 0;
  display: flex;
  box-sizing: border-box;
  padding: 15px 48px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  font-weight: 500;
  border-bottom: 1px solid rgb(255, 255, 255 0.7);
  box-shadow: 0 2px 5px -2px rgba(122, 122, 122, 0.2);
  z-index: 10;
}

.animateOpen > div {
  animation-duration: 250ms;
  animation-name: slidein;
}

.animateClose > div {
  animation-duration: 250ms;
  animation-name: slideout;
}

.subContainer {
  display: flex;
  gap: 24px;
  align-items: center;
}

a {
  text-decoration: none;
  color: rgba(50, 50, 50, 0.9);
}

a:hover {
  color: rgb(41, 153, 41);
}

.languageSelector {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}

.cartIcon {
  height: 30px;
  width: 30px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid rgb(41, 153, 41);
  padding: 4px;
  color: rgb(41, 153, 41);
  cursor: pointer;
  transition-duration: 250ms;
}

.cartIconLink:hover > svg {
  transition-duration: 250ms;
  border-color: rgb(41, 153, 41, 0.5);
  box-shadow: rgba(41, 153, 41, 0.3) 0px 4px 16px,
    rgba(41, 153, 41, 0.3) 0px 8px 24px, rgba(41, 153, 41, 0.3) 0px 16px 56px;
}

.productCount {
  position: absolute;
  translate: 21px -40px;
  height: 18px;
  width: 18px;
  line-height: 18px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 50%;
  color: white;
  background-color: rgb(41, 153, 41);
}

.navBarCartContainer {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  top: 58px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
}

.mockLogo {
  width: 65px;
  translate: 0 1px;
}

@media only screen and (max-width: 800px) {
  .container {
    padding: 18px 24px 12px 12px;
  }

  .subContainer {
    gap: 16px;
  }
  .navBarCartContainer {
    display: none;
  }
  .cartIconContainer:hover .navBarCartContainer {
    display: none;
  }

  .languageSelector {
    display: none;
  }

  .cartIconLink:hover > svg {
    box-shadow: none;
    border-color: rgb(41, 153, 41);
  }
}

@keyframes slidein {
  from {
    translate: 400px -10px;
  }

  to {
    translate: 0 -10px;
  }
}

@keyframes slideout {
  from {
    translate: 0 -10px;
  }

  to {
    translate: 400px -10px;
  }
}
