.App {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 65px;
  padding-bottom: 96px;
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 800px) {
  .backGroundImage {
    height: 250px;
    background-size: contain;
    background-position: 50% 100%;
  }
  .appGap {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 500px) {
  .App {
    padding-bottom: 92px;
  }
}
