@import "./variables.scss";

body {
  margin: 0;
  padding: 0;
  font-family: "DM Sans", Fallback, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(240, 240, 240);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  font-display: block;
}

@font-face {
  font-family: "DM Sans";
  src: url(assets/fonts/DMSans-Regular.ttf) format("opentype");
  font-display: block;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

label {
  color: rgb(99, 99, 99);
}

input:not([type="checkbox"]) {
  /* border-radius: 4px; */
  box-sizing: border-box;
  text-indent: 6px;
  width: 100%;
  max-width: 100%;
  border-radius: 2px;
  border-color: transparent;
  outline: 1px solid rgb(146, 146, 146);
  height: 28px;
  font-size: 14px;
}

input:not([type="checkbox"]):focus {
  outline: 1px solid #a7a7a7;
  box-shadow: 0 1px 4px 1px #d3d3d3;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="checkbox"] {
  accent-color: var(--primaryFontColor);
}

button {
  background-color: white;
  font-family: "DM Sans";
  cursor: pointer;
}

button:disabled {
  pointer-events: none;
}

p {
  margin: 0;
  color: var(--primaryFontColor);
  line-height: 30px;
}

h1 {
  font-size: 44px;
  line-height: 56px;
  margin: 0;
  color: var(--primaryFontColor);
}

li {
  list-style-type: none;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

@media only screen and (max-width: 1200px) {
  h1 {
    font-size: 30px;
    line-height: 42px;
  }
}

@media only screen and (max-width: 800px) {
  button {
    cursor: auto;
  }
  h1 {
    font-weight: 400;
  }
}
