.footerContainer {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  padding: 24px 48px;
  /* margin-top: 12px; */
  background-color: #000;
  color: white;
  align-items: center;
  justify-content: start;
  width: 100%;
  z-index: 3;
}

.footerContainer > :nth-last-child(1) {
  margin-left: 86px;
}

.footerSubContainer {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: fit-content;
}

.footerSubContainer > :nth-child(1) {
  margin-right: 8px;
}

.footerIcon {
  border-radius: 50%;
  font-size: 24px;
  background-color: #222326;
  padding: 12px;
}

.footerLink {
  color: white;
}

.navigation {
  display: flex;
  justify-content: start;
  gap: 14px;
  flex: 1;
}

a {
  color: inherit;
}

a:hover {
  color: inherit;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .footerContainer {
    flex-direction: column;
    gap: 8px;
  }

  .footerIcon {
    border-radius: 50%;
    font-size: 18px;
    background-color: #222326;
    padding: 10px;
  }

  .footerContainer > :nth-last-child(1) {
    margin-left: 0;
  }
}
